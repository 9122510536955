<div class="  ">
  <br><br>
  <h2 class=" mb-3 fw-bold" id="features_section">Orcaa.ai Suite Features</h2>
  <br>

  <div class="usecase-scroll" >
   <!-- Recruitment Feature Card -->
    <div class="usecase-card">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/recruitment-icons.svg" >
        <br>
        <h4 class="fw-bold text-center mt-2">Recruitment</h4>  
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Advertise Job Opportunities
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Promote your job vacancies effectively across multiple channels.  
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                     AI-Powered Personalised Interviews
                    </div>
                </div>
                <div class="feature-description">
                  Conduct tailored interviews using artificial intelligence to better assess candidates.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Customisable Recruitment Workflow
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Define and manage your own recruitment processes to suit your organisation’s unique needs.
                </p>
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Adaptive Interviews Based on Soft Skills
                  </div>
              </div>
              <div class="feature-description">
                Utilise adaptive interview techniques to evaluate candidates' soft skills, critical thinking abilities, and design competencies.
              </div>
          </li> 
 
        </ul> 
      </div>
    </div> 

     <!--  Recruitment Feature Card-->
    <div class="usecase-card">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/skills-auditing-icon.svg" >

        <h4 class="fw-bold text-center mt-2">Skills Auditing</h4> 
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      AI-Powered Adaptive Assessments
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Implement intelligent, adaptive assessments to evaluate skill levels accurately.  
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Define Learning or Training Skills:
                    </div>
                </div>
                <div class="feature-description">
                  Identify and outline the specific skills required for learning or training programmes.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Customisable Skills Auditing Workflow
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Create and tailor your own workflow for auditing skills to ensure it aligns with your organisational requirements.                 </p>
            </div>
          </li>  
        </ul> 
      </div>
    </div> 

    <!--  Learning & Training Feature Card -->
    <div class="usecase-card ">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/learning-icon.svg" >

        <h4 class="fw-bold text-center mt-2">Learning & Training</h4> 
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Host Paid or Free Courses
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Offer a variety of courses, whether monetised or complimentary, to meet diverse learning needs.  
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Create Personalised Learning Paths Based on Skills Audit                    </div>
                </div>
                <div class="feature-description">
                  Develop customised learning trajectories tailored to individual skill assessments.
                </div>
            </li>  
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Audit of Accredited Courses                  
                    </div>
                </div>
                <div class="feature-description">
                  Evaluate and verify the quality and accreditation of courses offered.                
                </div>
            </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  AI-Generated Content
                </div>
            </div>
            <div class="feature-description">
              Generate content using artificial intelligence to enhance and diversify learning materials.
            </div>
        </li> 
 
        </ul> 
      </div>
    </div> 

    <div class="usecase-card  ">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2 text-center" src="@/assets/images/assessment-icon.svg" >

        <h4 class="fw-bold text-center mt-2">Assessments</h4> 
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Automated and Scheduled AI-Powered Adaptive Assessments                      
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Set up automatic, AI-driven assessments that adapt to each learner’s progress and needs.  
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      AI-Powered Marking of Assessments                   
                    </div>
                </div>
                <div class="feature-description">
                  Utilise AI to grade assessments accurately and efficiently.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Plagiarism Checker
                  </div>
              </div>
              <div class="feature-description"> 
                Ensure the originality of submitted work with built-in plagiarism detection.      
              </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Generate Reporting on Defined Skills                 
                  </div>
              </div>
              <div class="feature-description">
                Produce detailed reports analysing the skills as outlined in assessments.
              </div>
          </li> 

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Seamless Data Integration from Previous LMS               
                </div>
            </div>
            <div class="feature-description">
              Easily integrate data from existing Learning Management Systems (LMS) to maintain continuity.
            </div>
          </li> 

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Skills-Audited Certificates               
                </div>
            </div>
            <div class="feature-description">
              Issue certificates based on audited skills, validating learners’ achievements.
            </div>
          </li>
  
        </ul> 
      </div>
    </div>  

  </div> 
  <br>
  <h2 class="mt-3 mb-3 fw-bold" id="more_features_section">More Features</h2>
  <br>
  <div class="usecase-scroll" > 
    <!--  KPI & Interventions Feature Car-->
    <div class="usecase-card   ">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/intervention-icon.svg" >

        <h4 class="fw-bold text-center mt-2">KPI & Interventions</h4> 
        <ul class="feature-list text-start">
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Link Learning Outcomes to Job or Course Performance                      
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Connect educational achievements with job performance metrics or course success.                  
                </p>
              </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Define Interventions for Struggling Students                   
                  </div>
              </div>
              <div class="feature-description">
                Create targeted interventions to support learners who are facing challenges.                
              </div>
          </li>  

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Link Learning to Company KPIs
                </div>
            </div>
            <div class="feature-description"> 
              Align learning programmes with your company’s key performance indicators (KPIs) to ensure organisational goals are met.         
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    AI-Powered Suggestions and Future Predictions                  
                  </div>
              </div>
              <div class="feature-description">
                Receive intelligent recommendations and forecasts to inform strategic decisions.
              </div>
          </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Monitor Intervention Progress with Learning Outcomes
                 </div>
            </div>
            <div class="feature-description">
              Track the effectiveness of interventions through continuous monitoring of learning outcomes.
            </div>
          </li>
        </ul> 
        <div class="featured-addons">
            
        </div>
      </div>
    </div> 

    <!--  Marketplace Feature Card -->
    <div class="usecase-card">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/market-icon.svg" >

        <h4 class="fw-bold text-center mt-2">Marketplace</h4> 
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Match Internal Job Openings in Recruitment Process                     
                      </div>
                </div>
                <div class="feature-description"> 
                  Connect internal job vacancies with suitable candidates within your organisation.                
                </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Allow Internal Candidates to Access New Job Opportunities                   
                    </div>
                </div>
                <div class="feature-description">
                  Enable existing employees to explore and apply for new roles within the company.        
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Match External Candidates' Skills to New Job Opportunities Advertised
                  </div>
              </div>
              <div class="feature-description"> 
                Align external candidates' skillsets with the requirements of advertised job positions.                
              </div>
          </li>  
  
        </ul>  
        <div class="featured-addons">
            
        </div>
      </div>
    </div> 
  </div>  
</div>
 