 
<div class="container-fluid  " id="pricelist">
  <br>
  <h2 class="  mb-3 fw-bold">Find The Perfect Suit </h2>
  <br>
  <div class="scrolling-wrapper">
    <!-- Assist Card -->
    <div class="usecase-card"> 
      <div class="feature-card bg-white h-100">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/Orcaa.ai_Assist.svg" >
          
        <h2 class="plan-name">Orcaa Assist</h2>
      
        <div class="price">
            P.O.A<span class="d-none"> ZAR per user/month</span>
        </div> 
        <div class="row mb-4 mt-1"> 
          <div class="col-12">
            <a href="https://demo.orcaa.ai/franchise_registration" target="_blank" class="btn btn-dark w-100">Start Trial</a>
          </div> 
        </div>
      
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                    Real-Time Academic Updates
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                  Parents receive instant updates on their children’s academic progress and achievements, helping them stay informed. 
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                    Integrated Behavioural Insights
                    </div>
                </div>
                <div class="feature-description">
                Track behavioural patterns to provide holistic support for student development.
              </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                  Scheduled Progress Report                     
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                Parents can set up recurring course progress reports to stay updated on their learner’s growth.                     
              </p>
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                  Parent-Purchased Intervention Courses
                  </div>
              </div>
              <div class="feature-description">
              Parents can purchase extra courses as part of intervention support, allowing tailored academic assistance.
            </div>
          </li> 

        </ul> 
      </div>
    </div> 

    <!-- Plus Card -->
    <div class="usecase-card"> 
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/Orcaa.ai_Plus.svg" >
          
        <h2 class="plan-name">Orcaa Plus</h2>
      
        <div class="price">
            P.O.A<span class="d-none"> ZAR per user/month</span>
        </div> 
        <div class="row mb-4 mt-1"> 
          <div class="col-12">
            <a  href="https://demo.orcaa.ai/franchise_registration" target="_blank" class="btn btn-dark w-100">Start Trial</a>
          </div> 
        </div>
      
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Define Learning Skills
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Identify and outline specific skills required for educational programs.
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Customisable Skills Auditing Workflow
                    </div>
                </div>
                <div class="feature-description">
                  Create workflows that align with institutional requirements.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Audit of Accredited Courses                    
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Evaluate and verify course quality and accreditation.
                </p>
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Plagiarism Checker
                  </div>
              </div>
              <div class="feature-description">
                Ensure originality in student submissions.
              </div>
          </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Skills-Audited Certificates                  
                </div>
            </div>
            <div class="feature-description">
              Issue certificates validating student achievements.
            </div>
          </li> 

        </ul> 
      </div>
    </div> 

    <!-- Recruit Card -->
    <div class="usecase-card"> 
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/Orcaa.ai_Recruit.svg" >
          
        <h2 class="plan-name">Orcaa Recruit</h2>
      
        <div class="price">
            P.O.A<span class="d-none"> ZAR per user/month</span>
        </div> 
        <div class="row mb-4 mt-1"> 
          <div class="col-12">
            <a  href="https://demo.orcaa.ai/franchise_registration" target="_blank" class="btn btn-dark w-100">Start Trial</a>
          </div> 
        </div>
      
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Advertise Job Opportunities
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Promote vacancies effectively across multiple channels.
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      AI-Powered Personalised Interviews
                    </div>
                </div>
                <div class="feature-description">
                  Conduct tailored interviews to assess candidates.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Customisable Recruitment Workflow                    
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Define and manage recruitment processes to suit organisational needs.
                </p>
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Generate Reporting on Critical Skills
                  </div>
              </div>
              <div class="feature-description">
                Produce detailed reports for hiring managers.
              </div>
          </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Seamless Data Integration
                </div>
            </div>
            <div class="feature-description">
              Integrate candidate data with existing systems to maintain continuity.
            </div>
          </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  AI-Powered Predictions and Insights
                </div>
            </div>
            <div class="feature-description">
              Forecast candidate success and hiring needs accurately.
            </div>
          </li> 

        </ul> 
      </div>

    </div>

     <!-- Corporate Card -->
    <div class="usecase-card" > 
      <div class="feature-card bg-white ">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/Orcaa.ai_Corporate.svg" >
          
        <h2 class="plan-name">Orcaa Corporate</h2>
      
        <div class="price">
            P.O.A<span class="d-none"> ZAR per user/month</span>
        </div> 
        <div class="row mb-4 mt-1"> 
          <div class="col-12">
            <a  href="https://demo.orcaa.ai/franchise_registration" target="_blank" class="btn btn-dark w-100">Start Trial</a>
          </div>
            
        </div>
      
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Define Employee Training Skills
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Identify and outline specific skills required for employee development programs.
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Link Training to Company KPIs
                    </div>
                </div>
                <div class="feature-description">
                  Align training programs with key performance indicators.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    AI-Powered Adaptive Assessments                    
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Implement intelligent assessments that adapt to employee progress.
                </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Customisable Skills Auditing Workflow
                    </div>
                </div>
                <div class="feature-description">
                  Tailor workflows to align skills auditing with your company’s strategic goals.
                </div>
            </li> 

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Host Paid or Free Training Modules
                </div>
            </div>
            <div class="feature-description">
              Provide flexibility in offering training content to meet diverse employee development needs.
            </div>
          </li> 
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Automated and Scheduled Assessments
                  </div>
              </div>
              <div class="feature-description">
                Set up assessments that adapt to each employee’s progress.
              </div>
          </li> 

        </ul> 
      </div>
    </div>

    <!-- Intership Card -->
    <div class="usecase-card"> 
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/Orcaa.ai_Internship.svg" >
          
        <h2 class="plan-name">Orcaa Internship</h2>
      
        <div class="price">
            P.O.A<span class="d-none"> ZAR per user/month</span>
        </div> 
        <div class="row mb-4 mt-1"> 
          <div class="col-12">
            <a href="https://demo.orcaa.ai/franchise_registration" target="_blank" class="btn btn-dark w-100">Start Trial</a>
          </div> 
        </div>
      
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Advertise Internship Opportunities
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Promote internships effectively across channels.
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Customisable Onboarding Workflow
                    </div>
                </div>
                <div class="feature-description">
                  Define processes to suit organisational needs.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    AI-Powered Adaptive Assessments
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Assess intern skills accurately.
                </p>
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Automated and Scheduled Assessments
                  </div>
              </div>
              <div class="feature-description">
                Monitor intern progress over time.
              </div>
          </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  AI-Powered Marking and Feedback.
                </div>
            </div>
            <div class="feature-description">
              Provide efficient grading and constructive feedback.
            </div>
          </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Generate Reporting on Defined Skills
                </div>
            </div>
            <div class="feature-description">
              Keep track of intern development.
            </div>
          </li> 

        </ul> 
      </div>


    </div> 

  </div>
  <br><br>

</div> 