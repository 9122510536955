<div class="container" id="sectors-section">
  <!-- Row for the heading -->
  <div class="row d-sm-flex justify-content-center align-items-center">
    <div class="col-12">
    
      <transition name="fade">
      
        
        <h1 id="about-topic" class="about-topic mt-5 text-center" key="aboutTopic">{{ aboutTopic }}</h1>
      </transition>
    </div>
  </div>

  <br>

  <!-- Row for the tabs -->
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{ active: selectedTab === 'tab1' }"
            id="Corporates-tab"
            type="button"
            @click="handleTabChange('tab1', 'Enhancing Workplace Skills with Real-Time Tracking')"
          >
            Corporates
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{ active: selectedTab === 'tab3' }"
            id="Education-tab"
            type="button"
            @click="handleTabChange('tab3', 'Transforming Educational Institutions in Real-Time Insights')"
          >
            Education
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{ active: selectedTab === 'tab5' }"
            id="Parents-tab"
            type="button"
            @click="handleTabChange('tab5', 'AI-Powered Recruitment Automation')"
          >
            Human Resource
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{ active: selectedTab === 'tab2' }"
            id="Internship-tab"
            type="button"
            @click="handleTabChange('tab2', 'Bridging the Gap Between Academics and Workplace Skills')"
          >
            Internship/WIL
          </button>
        </li>
      
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{ active: selectedTab === 'tab4' }"
            id="Parents-tab"
            type="button"
            @click="handleTabChange('tab4', 'Supporting Students with Real-Time Updates for Parents')"
          >
            Parents
          </button>
        </li>
       
      </ul>

      <!-- Tab Content -->
      <div class="tab-content" id="myTabContent">
        <div :class="['tab-pane fade', { 'show active': selectedTab === 'tab3' }]">
          <div class="row">
          
          <div class="col-12">
            <p class="Education-text text-center ms-3 mt-3">
              <b>Orcaa Plus</b> reduces administrative workload by automating tracking, assessments, and reporting. 
              With real-time insights and data-driven career guidance, it scales efficiently to support large student populations. 
            </p>
            <div class="card sectors-card sectors-card-pro new-style-sectors">
              <div class="row ">
                <div class="col-lg-6 col-12 align-content-center">                  
                  <h3 class="mini-topic text-center ms-4 fw-bold  ">Challenges Solved:</h3>
                  <br>
                  <ul class="text-start ms-4">
                    <li><b>Lack of Continuous Monitoring</b>: Automated tracking and real-time insights maintain student progress visibility. </li>
                    <li><b>Delayed Academic Support</b>: Adaptive assessments identify issues early, enabling timely interventions. </li>
                    <li><b>Personalized Career Advice</b>: Learning paths guided by performance data assist with tailored career guidance. </li>
                    <li><b>Administrative Overload</b>: Automated reporting reduces manual tasks, allowing staff to focus on student support. </li>
                  </ul>
                  <br>
                  <a type="button" class="btn btn-purple"  @click="scrollToPricelist"> Request Demo <i class='bx bx-right-arrow-alt'></i></a>
                </div>
                <div class="col-lg-6 col-12"> 
                  <img class="image-one mb-5" src="@/assets/images/orcaa-plus-mockup.png" alt="image" /> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div :class="['tab-pane fade', { 'show active': selectedTab === 'tab2' }]">
          <div class="row">
            <div class="col-12">
              <p class="Education-text text-center ms-3 mt-3">
                <b>Orcaa Internship</b> streamlines internship management, offering real-time performance insights and customizable training aligned with QCTO standards, 
                making it easy to track intern development across technical and professional skills.  
              </p>
              <div class="card sectors-card">
                <div class="row">
                  <div class="col-lg-6 col-12 align-content-center">
                 
                    <h2 class="mini-topic text-center ms-4 fw-bold  ">Challenges Solved:</h2>
                    <br>
                      <ul class="text-start ms-4">
                      <li><b>Aligning Education with Workforce Needs</b>: Skills auditing and performance monitoring ensure interns gain relevant experience. </li>
                      <li><b>High Onboarding Costs</b>: Structured onboarding workflows save time and resources by automating repetitive tasks. </li>
                      <li><b>Real-Time Monitoring</b>: Progress tracking provides ongoing visibility for educators and employers. </li>
                      <li><b>Building Talent Pipelines</b>: Custom training paths support targeted skill growth, preparing interns for full-time roles. </li>
                    </ul>
                    <a type="button" class="btn btn-purple"  @click="scrollToPricelist"> Request Demo <i class='bx bx-right-arrow-alt'></i></a>
                  </div>
                  <div class="col-lg-6 col-12"> 
                    <img class="image-one mb-5" src="@/assets/images/orcaa-intern-mockup.png" alt="image" /> 
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="['tab-pane fade', { 'show active': selectedTab === 'tab1' }]">
          <div class="row">
            <div class="col-12">
              <p class="Education-text text-start ms-3 mt-3">
                <b>Orcaa X</b> seamlessly integrates with corporate systems to improve workforce skills using personalized learning paths, 
                AI-powered assessments, and performance tracking, fostering continuous employee growth while addressing regional challenges. 
              </p>
              <div class="card sectors-card">
                <div class="row">
                  <div class="col-lg-6 col-12 align-content-center">
                   
                    <h2 class="mini-topic text-center ms-4 fw-bold  ">Challenges Solved:</h2>
                    <br>                      
                    <ul class="text-start  ms-4">
                      <li><b>Skill Development Needs</b>: Tailored learning paths and skills audits target specific skill gaps effectively. </li>
                      <li><b>Ongoing Performance Monitoring</b>: Live tracking supports continuous development and accountability. </li>
                      <li><b>Regional Adaptability</b>: Locally relevant content and custom workflows meet unique regional demands. </li>
                      <li><b>Efficient Integration</b>: Seamless system compatibility ensures smooth adoption without disrupting existing processes. </li>
                    </ul>
                    <br>
                    <a type="button" class="btn btn-purple"  @click="scrollToPricelist"> Request Demo <i class='bx bx-right-arrow-alt'></i></a>
                  </div>
                  <div class="col-lg-6 col-12">
                    <img class="image-one mb-5" src="@/assets/images/orcaa-corp-mockup.png" alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="['tab-pane fade', { 'show active': selectedTab === 'tab4' }]">
          <div class="row">
            <div class="col-12">
              <p class="Education-text text-center ms-3 mt-3">
                <b>Orcaa Assist</b> links parents, tutors, and schools through a platform that provides real-time student progress updates, 
                adaptive assessments, and intervention planning, supporting students effectively without burdening teachers or administrators.
              </p>
              <div class="card sectors-card">
                <div class="row">
                  <div class="col-lg-6 col-12 align-content-center">                  
                    <h2 class="mini-topic text-center ms-4 fw-bold  ">Challenges Solved:</h2>
                    <br>                      
                    <ul class="text-start ms-4">                        
                      <li><b>Lack of Transparency</b>: Live progress tracking offers clear visibility for parents and mentors. </li>
                      <li><b>Engagement with Stakeholders</b>: Customizable notifications keep all parties informed and involved. </li>
                      <li><b>Overloaded Teachers</b>: Automated updates simplify progress reporting, allowing teachers to focus on instruction. </li>
                      <li><b>Limited Learning Tools</b>: AI-generated resources adapt to each student’s learning needs. </li>
                    </ul>
                    <br>
                    <a type="button" class="btn btn-purple"  @click="scrollToPricelist"> Request Demo <i class='bx bx-right-arrow-alt'></i></a>
                  </div>
                  <div class="col-lg-6 col-12"> 
                    <img class="image-one mb-5" src="@/assets/images/orcaa-assist-mockup.png" alt="image" /> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="['tab-pane fade', { 'show active': selectedTab === 'tab5' }]">
          <div class="row">
            <div class="col-12">
              <p class="Education-text text-center ms-3 mt-3">
                <b>Orcaa Recruit</b> enhances recruitment by automating processes with AI-driven interviews, adaptive assessments,
                 and customizable workflows, helping HR save time and match candidates accurately to roles.
              </p>
              <div class="card sectors-card">
                <div class="row">
                  <div class="col-lg-6 col-12 align-content-center">                  
                    <h2 class="mini-topic text-center ms-4 fw-bold  ">Challenges Solved:</h2>
                    <br>                      
                    <ul class="text-start ms-4">                        
                      <li><b>Lengthy Hiring Processes</b>: Automated workflows expedite recruitment tasks, letting HR focus on high-priority activities. </li>
                      <li><b>High Hiring Costs</b>: AI-driven assessments and adaptive interviews minimize costs by focusing on top-fit candidates.  </li>
                      <li><b>Quality Candidate Matching</b>: Personalized interview tools align candidate skills with role requirements.  </li>
                      <li><b>Pipeline Visibility</b>: Real-time pipeline insights allow HR to monitor candidate progress seamlessly. </li>
                    </ul>
                    <br>
                    <a type="button" class="btn btn-purple"  @click="scrollToPricelist"> Request Demo <i class='bx bx-right-arrow-alt'></i></a>
                  </div>
                  <div class="col-lg-6 col-12"> 
                    <img class="image-one mb-5" src="@/assets/images/orcaa-recruit-mockup.png" alt="image" /> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>