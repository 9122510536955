<div class="about_page_background  ">
  <br><br>
  <div class="container orcaa-stat">
    <h1 class="header">Driving Excellence in Talent Development  <span>with Data-Driven
        Precision </span> </h1>
    <div class="row">
        <div class="col-md-3 col-sm-6">
            <div class="progress-item">
                 <div class="circle-progress cyan">
                    <div class="progress-icon">50%</div>
                </div>
                <div class="progress-title fw-bold">Employee Engagement</div>
                <div class="progress-description">
                  Companies that invest in onboarding see a 50% increase in employee retention. A robust onboarding solution like Orcaa.ai Recruit could underscore this stat to highlight improved retention rates. (Source: SHRM)
                  </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="progress-item">
                 <div class="circle-progress blue">
                    <div class="progress-icon">30%</div>
                </div>
                <div class="progress-title fw-bold">Skills Development and Retention</div>
                <div class="progress-description"> 
                  Learners who engage in interactive and data-driven learning programs see up to a 30% increase in knowledge retention. Orcaa.ai Assist can be positioned as boosting retention rates through its data-backed learning approach. (Source: Training Magazine)
                  </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="progress-item">
                 <div class="circle-progress purple">
                    <div class="progress-icon">70%</div>
                </div>
                <div class="progress-title fw-bold">Internship Productivity</div>
                <div class="progress-description">
                  Structured internship programs boost employee productivity by up to 70% in their early employment phase. Orcaa.ai Internships can demonstrate this impact through faster onboarding and skill acquisition. (Source: NACE)
                  </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="progress-item"> 
                <div class="circle-progress pink">
                    <div class="progress-icon">57%</div>
                </div>
                <div class="progress-title fw-bold">Talent-Role Alignment </div>
                <div class="progress-description"> 
                  Employees are 57% more effective when their roles match their strengths and competencies, which speaks to Orcaa.ai Plus' impact on talent matching. (Source: Gallup)

                </div>
            </div>
        </div>
    </div>
    <br><br>
</div>
    <div class="row d-none">
      <div class="col-12">
        <h2 class="stats-topic text-black display-6">
          Driving Excellence in Talent Development with Data-Driven <br>
          Precision
        </h2>
        <br>
        <h4 class="stats-topic-two text-muted display-6 fw-small">
          Harnessing powerful insights to optimize performance, improve learning outcomes,<br>
           and create future-ready teams.
        </h4>
        <br>
        <br>
      </div>
      
      <div class="row statistics-row">
        <div class="col-4">
          <h1 :class="['stats-topic-three', { 'glow': stat1 === target1 }]" class="fw-bold">{{ stat1 }}%</h1>
          <h4 class="stats-topic-two text-muted display-6 fw-small last-topic">
            Boost in learner pass rates & <br>
             skills retention.
          </h4>
        </div>
        <div class="col-4">
          <h1 :class="['stats-topic-three', { 'glow': stat2 === target2 }]" class="fw-bold">{{ stat2 }}%</h1>
          <h4 class="stats-topic-two text-muted display-6 fw-small last-topic">
            Quicker onboarding and <br>
             training lead to faster <br>
              productivity and improved <br>
              efficiency.
          </h4>
        </div>
        <div class="col-4">
          <h1 :class="['stats-topic-three', { 'glow': stat3 === target3 }]" class="fw-bold">{{ stat3 }}%</h1>
          <h4 class="stats-topic-two text-muted display-6 fw-small last-topic">
            Improvement in talent <br>
             matching with roles that <br>
              enhance their strengths.
          </h4>
        </div>
      </div>
      
    </div>
  </div>